<template>
  <v-dialog
    :overlay-opacity="0.9"
    :value="dialogs.teamsDialog"
    scrollable
    width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        class="pr-0"
        color="primary"
        dark
      >
        <v-toolbar-title>
          {{
            forTeamsDialog.dialog === 'add' ? 'Привязка команд к флагу' : 'Привязаны к флагу'
          }}
          {{ forTeamsDialog.featureFlag.featureKey }}
        </v-toolbar-title>

        <v-spacer/>

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text
        class="pt-5"
      >
        <!-- РЕДАКТИРОВАТЬ КОМАНДЫ -->
        <v-data-table
          v-if="forTeamsDialog.dialog === 'edit'"
          :headers="cols"
          :items="forTeamsDialog.featureFlag.teams"
          :items-per-page="-1"
          fixed-header
          height="400"
          hide-default-footer
          item-key="id"
        >
          <!-- УДАЛИТЬ КОМАНДЫ -->
          <template #[`item.actions`]="{ item }">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  :loading="loading"
                  color="error"
                  icon
                  @click="detachTeam(item.id)"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Отвязать команду</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <!-- ДОБАВИТЬ КОМАНДУ -->
        <v-row v-else>
          <!-- ID -->
          <v-col :cols="12">
            <v-text-field
              v-model="pivotTeamId"
              :spellcheck="false"
              dense
              hide-details
              label="ID команды"
              outlined
              type="number"
            />
          </v-col>

          <v-col :cols="12">
            или
          </v-col>

          <!-- EMAIL -->
          <v-col :cols="12">
            <v-text-field
              v-model="pivotTeamMail"
              :spellcheck="false"
              dense
              hide-details
              label="Email"
              outlined
              type="email"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider/>

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer/>

        <v-btn
          color="grey"
          small
          text
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          v-if="forTeamsDialog.dialog === 'add'"
          :disabled="disablePosting"
          :loading="loading"
          color="primary"
          small
          text
          @click="addTeam()"
        >
          Добавить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'FeatureFlagsTeamsDialog',

  data() {
    return {
      loading: false,
      pivotTeamId: null,
      pivotTeamMail: null,
    };
  },

  computed: {
    ...mapGetters({ dialogs: 'featureFlags/dialogs' }),
    ...mapFields('featureFlags', ['forTeamsDialog']),

    disablePosting() {
      return (this.$_.isEmpty(this.pivotTeamId) && this.$_.isEmpty(this.pivotTeamMail))
        || this.loading;
    },

    cols() {
      const cols = [];

      cols.push({
        text: '',
        value: 'actions',
        width: 30,
        sortable: false,
      });
      cols.push({ text: 'Команды', value: 'name' });

      return cols;
    },
  },

  beforeDestroy() {
    this.$store.dispatch('featureFlags/clearForTeamsDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('featureFlags/closeDialog', 'teamsDialog');
    },

    async addTeam() {
      try {
        this.loading = true;

        const pivotData = {
          featureFlagId: this.forTeamsDialog.featureFlag.id,
          teamId: this.pivotTeamId ? this.pivotTeamId : undefined,
          teamMail: this.pivotTeamMail ? this.pivotTeamMail : undefined,
        };

        const response = await this.apiv2.post('/admin/feature-flags/teams', pivotData);
        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('featureFlags/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } catch (e) {
        console.error(e.response?.data);
        this.$store.dispatch('main/alert', {
          color: 'error',
          message: e.response?.data?.details?.message ?? e.message,
        });
      } finally {
        this.loading = false;
      }
    },

    async detachTeam(teamId) {
      try {
        this.loading = true;

        const data = {
          featureFlagId: this.forTeamsDialog.featureFlag.id,
          teamId,
        };

        const response = await this.apiv2.delete('/admin/feature-flags/teams', { data });
        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('featureFlags/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } catch (e) {
        console.error(e.response?.data);
        this.$store.dispatch('main/alert', {
          color: 'error',
          message: e.response?.data?.details?.message ?? e.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.not-full-switch .v-input__slot {
  display: block !important;
}

.not-full-switch .v-input__slot:hover {
  cursor: default !important;
}
</style>
