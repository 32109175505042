var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.cols,"height":_vm.innerHeight - 16 - 46 - 59,"items":_vm.featureFlags.all,"items-per-page":_vm.footerOptions.itemsPerPage,"loading":_vm.loading.mainTable,"fixed-header":"","hide-default-footer":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.featureKey",fn:function(ref){
var featureFlag = ref.item;
return [_c('span',{staticClass:"main-table-link",on:{"click":function($event){return _vm.editFeatureFlag(featureFlag)}}},[_vm._v(" "+_vm._s(featureFlag.featureKey)+" ")])]}},{key:"item.isEnableForAll",fn:function(ref){
var item = ref.item;
return [(item.isEnableForAll)?_c('v-icon',{attrs:{"size":16,"color":"success"}},[_vm._v(" mdi-checkbox-marked ")]):_vm._e()]}},{key:"item.teams",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.teams.length > 0 ? item.teams.length : '')+" "),(item.teams.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editFeatureFlagTeams(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактировать команды")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.addFeatureFlagTeams(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-multiple-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Включить команде")])])]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.users.length > 0 ? item.users.length : '')+" "),(item.users.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editFeatureFlagUsers(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактировать пользователей")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.addFeatureFlagUsers(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Включить пользователю")])])]}},{key:"item.isForCore",fn:function(ref){
var item = ref.item;
return [(item.isForCore)?_c('v-icon',{attrs:{"size":16,"color":"success"}},[_vm._v(" mdi-checkbox-marked ")]):_vm._e()]}},{key:"item.criteria",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.criteria)+" ")]}},{key:"footer",fn:function(){return [_c('v-data-footer',{attrs:{"items-per-page-options":[10, 30, 50],"options":_vm.footerOptions,"pagination":_vm.footerPagination},on:{"update:options":_vm.updateFooterOptions}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }