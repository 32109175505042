<template>
  <v-dialog
    :overlay-opacity="0.9"
    :value="dialogs.mainDialog"
    scrollable
    width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        class="pr-0"
        color="primary"
        dark
      >
        <v-toolbar-title>
          {{ forMainDialog.dialog === 'add' ? 'Добавить флаг' : 'Изменить флаг' }}
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-row>
          <!-- КЛЮЧ -->
          <v-col :cols="12">
            <v-text-field
              v-model="forMainDialog.featureFlag.featureKey"
              :spellcheck="false"
              dense
              hide-details
              label="Флаг"
              outlined
            />
          </v-col>

          <!-- НАЗВАНИЕ -->
          <v-col :cols="12">
            <v-text-field
              v-model="forMainDialog.featureFlag.title"
              :spellcheck="false"
              dense
              hide-details
              label="Название"
              outlined
            />
          </v-col>

          <!-- ОПИСАНИЕ -->
          <v-col :cols="12">
            <v-text-field
              v-model="forMainDialog.featureFlag.description"
              :spellcheck="false"
              dense
              hide-details="auto"
              label="Описание"
              outlined
            />
          </v-col>

          <!-- ВКЛЮЧЕН ДЛЯ ВСЕХ -->
          <v-col :cols="12">
            <v-switch
              v-model="forMainDialog.featureFlag.isEnableForAll "
              class="mt-0"
              dense
              hide-details
              label="Включен для всех"
              style="display: block"
            />
          </v-col>

          <!-- ПРОЦЕНТ КОМАНД -->
          <v-col :cols="6">
            <v-text-field
              v-model="forMainDialog.featureFlag.percentageByTeam"
              :spellcheck="false"
              dense
              hide-details
              label="Процент команд"
              max="100"
              min="0"
              outlined
              type="number"
            />
          </v-col>

          <!-- ПРОЦЕНТ ПОЛЬЗОВАТЕЛЕЙ -->
          <v-col :cols="6">
            <v-text-field
              v-model="forMainDialog.featureFlag.percentageByUser"
              :spellcheck="false"
              dense
              hide-details
              label="Процент пользователей"
              max="100"
              min="0"
              outlined
              type="number"
            />
          </v-col>

          <!-- ДЛЯ ЯДРА -->
          <v-col
            :cols="12"
            class="not-full-switch"
          >
            <v-switch
              v-model="forMainDialog.featureFlag.isForCore"
              class="mt-0"
              dense
              hide-details
              label="Для ядра"
              style="display: block"
            />
          </v-col>

          <!-- КРИТЕРИИ -->
          <v-col :cols="12">
            <v-text-field
              v-model="forMainDialog.featureFlag.criteria"
              :spellcheck="false"
              dense
              hide-details="auto"
              label="Другие критерии (JSON)"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="grey"
          small
          text
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          :disabled="disablePosting"
          :loading="loading"
          color="primary"
          small
          text
          @click="forMainDialog.dialog === 'add' ? addFeatureFlag() : editFeatureFlag()"
        >
          {{ forMainDialog.dialog === 'add' ? 'Добавить' : 'Сохранить' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'FeatureFlagsMainDialog',

  data() {
    return {
      menu: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({ dialogs: 'featureFlags/dialogs' }),
    ...mapFields('featureFlags', ['forMainDialog']),

    disablePosting() {
      const { featureFlag } = this.forMainDialog;
      return this.$_.isEmpty(featureFlag.featureKey);
    },
  },

  beforeDestroy() {
    this.$store.dispatch('featureFlags/clearForDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('featureFlags/closeDialog', 'mainDialog');
    },

    async addFeatureFlag() {
      try {
        this.loading = true;

        const featureFlag = {
          featureKey: this.forMainDialog.featureFlag.featureKey,
          title: this.forMainDialog.featureFlag.title,
          description: this.forMainDialog.featureFlag.description,
          isEnableForAll: this.forMainDialog.featureFlag.isEnableForAll,
          percentageByTeam: this.forMainDialog.featureFlag.percentageByTeam,
          percentageByUser: this.forMainDialog.featureFlag.percentageByUser,
          criteria: this.forMainDialog.featureFlag.criteria,
          isForCore: this.forMainDialog.featureFlag.isForCore,
        };

        const response = await this.apiv2.post('/admin/feature-flags', featureFlag);
        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('featureFlags/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } catch (_) {
        // Do nothing
      } finally {
        this.loading = false;
      }
    },

    async editFeatureFlag() {
      try {
        this.loading = true;
        const featureFlag = {
          featureKey: this.forMainDialog.featureFlag.featureKey,
          title: this.forMainDialog.featureFlag.title,
          description: this.forMainDialog.featureFlag.description,
          isEnableForAll: this.forMainDialog.featureFlag.isEnableForAll,
          percentageByTeam: this.forMainDialog.featureFlag.percentageByTeam,
          percentageByUser: this.forMainDialog.featureFlag.percentageByUser,
          criteria: this.forMainDialog.featureFlag.criteria,
          isForCore: this.forMainDialog.featureFlag.isForCore,
        };

        const response = await this.apiv2.patch(
          `/admin/feature-flags/${this.forMainDialog.featureFlag.id}`, featureFlag,
        );
        if (response.status === 200) {
          this.closeDialog();
          this.$store.dispatch('featureFlags/loadCurrentPage');
          this.$store.dispatch('main/alert', {
            color: 'success',
            message: 'Готово',
          });
        }
      } catch (e) {
        console.error(e.response?.data);
        this.$store.dispatch('main/alert', {
          color: 'error',
          message: e.response?.data?.details?.message ?? e.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.not-full-switch .v-input__slot {
  display: block !important;
}

.not-full-switch .v-input__slot:hover {
  cursor: default !important;
}
</style>
