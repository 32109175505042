<template>
  <div>
    <main-dialog v-if="dialogs.mainDialog" />
    <users-dialog v-if="dialogs.usersDialog" />
    <teams-dialog v-if="dialogs.teamsDialog" />
    <topbar />
    <main-table />
  </div>
</template>

<script>
import Topbar from '@/components/featureFlags/FeatureFlagsTopbar.vue';
import MainTable from '@/components/featureFlags/FeatureFlagsMainTable.vue';
import MainDialog from '@/components/featureFlags/FeatureFlagsMainDialog.vue';
import UsersDialog from '@/components/featureFlags/FeatureFlagsUsersDialog.vue';
import TeamsDialog from '@/components/featureFlags/FeatureFlagsTeamsDialog.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'FeatureFlags',
  components: {
    MainTable,
    Topbar,
    MainDialog,
    UsersDialog,
    TeamsDialog,
  },

  computed: {
    ...mapGetters({
      dialogs: 'featureFlags/dialogs',
    }),
  },

  created() {
    this.$store.dispatch('featureFlags/loadFeatureFlags');
  },
};
</script>
