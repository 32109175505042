<template>
  <v-card>
    <v-data-table
      :headers="cols"
      :height="innerHeight - 16 - 46 - 59"
      :items="featureFlags.all"
      :items-per-page="footerOptions.itemsPerPage"
      :loading="loading.mainTable"
      fixed-header
      hide-default-footer
      item-key="id"
    >
      <!-- ФЛАГ -->
      <template #[`item.featureKey`]="{item: featureFlag}">
        <span
          class="main-table-link"
          @click="editFeatureFlag(featureFlag)"
        >
          {{ featureFlag.featureKey }}
        </span>
      </template>

      <!-- ВКЛЮЧЕН ДЛЯ ВСЕХ -->
      <template #[`item.isEnableForAll`]="{item}">
        <v-icon
          v-if="item.isEnableForAll"
          :size="16"
          color="success"
        >
          mdi-checkbox-marked
        </v-icon>
      </template>

      <!-- ВКЛЮЧЕН ДЛЯ КОМАНД -->
      <template #[`item.teams`]="{item}">
        {{ item.teams.length > 0 ? item.teams.length : '' }}

        <v-tooltip
          v-if="item.teams.length > 0"
          top
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
              icon
              @click="editFeatureFlagTeams(item)"
              v-on="on"
            >
              <v-icon>mdi-account-edit</v-icon>
            </v-btn>
          </template>
          <span>Редактировать команды</span>
        </v-tooltip>

        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              color="primary"
              icon
              @click="addFeatureFlagTeams(item)"
              v-on="on"
            >
              <v-icon>mdi-account-multiple-plus</v-icon>
            </v-btn>
          </template>
          <span>Включить команде</span>
        </v-tooltip>
      </template>

      <!-- ВКЛЮЧЕН ДЛЯ ПОЛЬЗВОАТЕЛЕЙ -->
      <template #[`item.users`]="{item}">
        {{ item.users.length > 0 ? item.users.length : '' }}

        <v-tooltip
          v-if="item.users.length > 0"
          top
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
              icon
              @click="editFeatureFlagUsers(item)"
              v-on="on"
            >
              <v-icon>mdi-account-edit</v-icon>
            </v-btn>
          </template>
          <span>Редактировать пользователей</span>
        </v-tooltip>

        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              color="primary"
              icon
              @click="addFeatureFlagUsers(item)"
              v-on="on"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <span>Включить пользователю</span>
        </v-tooltip>
      </template>

      <!-- ДЛЯ ЯДРА -->
      <template #[`item.isForCore`]="{item}">
        <v-icon
          v-if="item.isForCore"
          :size="16"
          color="success"
        >
          mdi-checkbox-marked
        </v-icon>
      </template>

      <!-- КРИТЕРИИ -->
      <template #[`item.criteria`]="{item}">
        {{ item.criteria }}
      </template>

      <!-- ФУТЕР -->
      <template #footer>
        <v-data-footer
          :items-per-page-options="[10, 30, 50]"
          :options="footerOptions"
          :pagination="footerPagination"
          @update:options="updateFooterOptions"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'FeatureFlagsMainTable',

  computed: {
    ...mapGetters({
      innerHeight: 'main/innerHeight',
      loading: 'featureFlags/loading',
      pagination: 'featureFlags/pagination',
    }),
    ...mapFields('featureFlags', ['featureFlags']),

    cols() {
      const cols = [];

      cols.push({ text: 'Флаг', value: 'featureKey' });
      cols.push({ text: 'Название', value: 'title' });
      cols.push({ text: 'Описание', value: 'description' });
      cols.push({ text: 'Включен для всех', value: 'isEnableForAll' });
      cols.push({ text: 'Процент команд', value: 'percentageByTeam' });
      cols.push({ text: 'Процент юзеров', value: 'percentageByUser' });
      cols.push({ text: 'Включен индивидуально для команд', value: 'teams' });
      cols.push({ text: 'Включен индивидуально для юзеров', value: 'users' });
      cols.push({ text: 'Другие критерии', value: 'criteria' });
      cols.push({ text: 'Для ядра', value: 'isForCore' });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  methods: {
    updateFooterOptions(options) {
      this.$store.dispatch('featureFlags/loadFeatureFlags', {
        page: options.page,
        itemsPerPage: options.itemsPerPage,
      });
    },

    editFeatureFlag(featureFlag) {
      this.$store.dispatch('featureFlags/setFeatureFlagForMainDialog', featureFlag);
      this.$store.dispatch('featureFlags/openDialog', 'mainDialog');
    },

    addFeatureFlagUsers(featureFlag) {
      this.$store.dispatch(
        'featureFlags/setFeatureFlagForUsersDialog',
        { ...featureFlag, dialogMode: 'add' },
      );
      this.$store.dispatch('featureFlags/openDialog', 'usersDialog');
    },

    editFeatureFlagUsers(featureFlag) {
      this.$store.dispatch(
        'featureFlags/setFeatureFlagForUsersDialog',
        { ...featureFlag, dialogMode: 'edit' },
      );
      this.$store.dispatch('featureFlags/openDialog', 'usersDialog');
    },

    addFeatureFlagTeams(featureFlag) {
      this.$store.dispatch(
        'featureFlags/setFeatureFlagForTeamsDialog',
        { ...featureFlag, dialogMode: 'add' },
      );
      this.$store.dispatch('featureFlags/openDialog', 'teamsDialog');
    },

    editFeatureFlagTeams(featureFlag) {
      this.$store.dispatch(
        'featureFlags/setFeatureFlagForTeamsDialog',
        { ...featureFlag, dialogMode: 'edit' },
      );
      this.$store.dispatch('featureFlags/openDialog', 'teamsDialog');
    },
  },
};
</script>
