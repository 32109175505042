<template>
  <div class="d-flex align-center justify-space-between mb-2">
    <!-- ЛЕВАЯ СТОРОНА -->
    <div/>

    <!-- ПРАВАЯ СТОРОНА -->
    <div class="d-flex align-center">
      <!-- ДОБАВИТЬ БИЛД -->
      <v-tooltip
        v-if="profile.manageFeatureFlags"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            class="mr-2"
            color="primary"
            icon
            @click="addFeatureFlag"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Добавить флаг</span>
      </v-tooltip>

      <!-- ОБНОВИТЬ СПИСОК -->
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            :disabled="loading.mainTable"
            class="ml-2 mr-4"
            color="primary"
            icon
            @click="$store.dispatch('featureFlags/loadCurrentPage')"
            v-on="on"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FeatureFlagsTopbar',

  computed: {
    ...mapGetters({
      loading: 'featureFlags/loading',
      profile: 'main/profile',
    }),
  },

  methods: {
    async addFeatureFlag() {
      await this.$store.dispatch('featureFlags/setFeatureFlagForMainDialog');
      this.$store.dispatch('featureFlags/openDialog', 'mainDialog');
    },
  },
};
</script>
